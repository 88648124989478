import React, { useState, useEffect, useRef } from "react";
// react-bootstrap components
import {
  Card,
  Col,
} from "react-bootstrap";

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

var Favorite = React.memo((props) => {
    var game = props.game || {}
    var player = props.player || {}
    var usersTailing = props.usersTailing || 0
    var live = props.live || {first:{teamMade:{}}}
    var favorite = props.favorite || {}
    var pickFavorite = props.pickFavorite || {}
    var isFavorite = favorite.playerId == player.personId
    var threesMade = props.live[props.game?.gameId]?.players[props.player?.personId]?.live?.threesMade || 0
    var threesAttempted = props.live[props.game?.gameId]?.players[props.player?.personId]?.live?.threesAttempted || 0
    const [showPlusOne, setShowPlusOne] = useState(false);
    const [showAttempt, setShowAttempt] = useState(false);
    const [showMade, setShowMade] = useState(false);
    const prevPropRef = useRef(props);

    useEffect(() => {
        if (prevPropRef.usersTailing !== props.usersTailing) {
          setShowPlusOne(true);
          setTimeout(() => setShowPlusOne(false), 2000);
        }

        if (prevPropRef.threesAttempted !== threesAttempted) {
            setShowAttempt(true);
            setTimeout(() => setShowAttempt(false), 2000);
        }
        if (prevPropRef.threesMade !== threesMade) {
            setShowMade(true);
            setTimeout(() => setShowMade(false), 2000);
        }
        prevPropRef.threesAttempted = threesAttempted;
        prevPropRef.threesMade = threesMade;
        prevPropRef.usersTailing = props.usersTailing
      }, [props, threesMade, threesAttempted]);

    return (
      <>
          <tr style={{backgroundColor: "#242424", padding:"5px", margin:"-5px", borderRadius:"5px"}}>
            <td   style={{border: "2px solid #242424", padding:"5px"}}>
                <div style={{width:100}}>
                    <span className="player-name" style={{fontSize:8}}>
                        {(isFavorite)?"⭐ "+player.playerName:player.playerName}
                    </span>
                </div>
                
                {(isFavorite)?
                    <span style={{width:"100%", background:(isFavorite)?"green":"bg-secondary"}} className="badge">
                        {(player.odds && player.odds != pickFavorite.odds)?
                            <>
                                <span>Original Odds: +{pickFavorite.odds}<br/></span>
                                <span>Current Odds: {player.odds}<br/></span>
                            </>
                            :
                            <>
                                <span>Odds: +{pickFavorite.odds}<br/></span>
                            </>
                        }
                    </span>
                :null}

              {(live[game.gameId]?.first?.made && live[game.gameId].first.made.personId == player.personId)?
                    <span style={{width:"100%", backgroundColor:(isFavorite)?"gold":"", color:(isFavorite)?"black":""}} className={(isFavorite)?"badge ":"badge bg-secondary"}>🏆&nbsp;First 3 Made</span>
                    :
                    <span style={{width:"100%", backgroundColor:(isFavorite)?"gold":"", backgroundColor:(isFavorite)?"gold":"", color:(isFavorite)?"black":""}} className={(isFavorite)?"badge ":"badge bg-secondary"}>{
                    (live[game.gameId]?.first.teamMade && live[game.gameId]?.first.teamMade[player.teamId]?.personId == player.personId)?
                        <span>🏆&nbsp;First 3 Made</span>
                        :""}
                    </span>
                }

                {(live[game.gameId] && live[game.gameId].first.attempt && live[game.gameId].first.attempt.personId == player.personId)?
                    <span style={{width:"100%"}} className="badge bg-secondary">✓&nbsp;First 3 Attempt</span>
                    :
                    <span style={{width:"100%"}} className="badge bg-secondary">{
                        (live[game.gameId]?.first.teamAttempt && live[game.gameId]?.first.teamAttempt[player.teamId]?.personId == player.personId)?
                            <span>✓&nbsp;First 3 Attempt</span>
                        :""}
                    </span>
                }

                {(live[game.gameId] && live[game.gameId].lastPlay && live[game.gameId].gameStatusText != "Final" && live[game.gameId].players[player.personId] && live[game.gameId].players[player.personId].onCourt==true)?
                    <span className="badge bg-secondary" style={{width:"100%"}}>On the Court</span>
                :""}
                {(usersTailing)?
                <span className="badge bg-primary text-white" style={{width:"100%", position:"relative"}}>
                    {usersTailing} Users Tailing
                    {showPlusOne && (
                        <span className="plus-one">+1</span>
                    )}
                </span>:null}
                
            </td>
            {(live)?
                <>
                  <td className="score-box" style={{position:'relative'}}>
                    {threesMade}
                    {showMade && (
                        <span className="plus-one">+1</span>
                    )}
                  </td>                                
                  <td className="score-box" style={{position:'relative'}}>
                    {threesAttempted}
                    {showAttempt && (
                        <span className="plus-one">+1</span>
                    )}
                </td>
                </>:
              <></>}
            </tr>
        </>
    )
  },(prevProps, nextProps) => {
    //return prevProps.usersTailing === nextProps.usersTailing && prevProps.live === nextProps.live; // Prevent re-render if prop is the same
  })

var FavoriteCard = React.memo((props) => {
    //React.useState()
    //  const [picksFavorites, setPicksFavorites] = React.useState("")
    var favorites = props.favorites || {}
    var game = props.game || {}
    var live = props.live || {}
    var executeScroll = props.executeScroll || {}
    var gidx = props.gidx || 0
    var userPicks = props.userPicks || {}

    var picksFavorites = props.picksFavorites || {} // this is the non cached one that is refreshed from the jwt call
    var favoriteHome = favorites[game.gameId].teams[game.homeTeam.teamId]?.players
    var favoriteAway = favorites[game.gameId].teams[game.awayTeam.teamId]?.players
    var favIdx = ((favoriteHome)?Object.keys(favoriteHome):Object.keys(favoriteAway))[0]
    var favorite = (favoriteHome)?favoriteHome[favIdx]:favoriteAway[favIdx]
    var favoriteWon = (live[game.gameId] && live[game.gameId].first.teamMade[favorite.teamId].personId == favorite.playerId)?true:false
    var pickFavoriteHome = picksFavorites[game.gameId]?.teams[game.homeTeam.teamId]?.players
    var pickFavoriteAway = picksFavorites[game.gameId]?.teams[game.awayTeam.teamId]?.players
    var pickFavorite = {}
    if(pickFavoriteHome || pickFavoriteAway){
        var favIdx = ((pickFavoriteHome)?Object.keys(pickFavoriteHome):Object.keys(pickFavoriteAway))[0]
        pickFavorite = (pickFavoriteHome)?pickFavoriteHome[favIdx]:pickFavoriteAway[favIdx]
    } 
    
    return(
      <>
      <Col md="3" xs={(Object.keys(favorites).length==1)?"12":"6"}>
      <div className="favorite-header">
                {game.awayTeam.teamTricode} 
                <div class="favorite-score">
                    {(live[game.gameId]?.awayTeamScore)?live[game.gameId]?.awayTeamScore:"0"}
                </div>
                &nbsp;@&nbsp;
                {game.homeTeam.teamTricode}
                <div class="favorite-score">
                    {(live[game.gameId]?.homeTeamScore)?live[game.gameId]?.homeTeamScore:"0"}
                </div>
              </div>
        <Card className={(favoriteWon?"favorite-winner":"")} style={{margin: "-4px 0px 4px 0px", fontSize:"8px !important"}}>
          <Card.Header>
            <Card.Title as="h6" style={{background:"#6e6d6b"}}>
              
              {(favoriteWon)?
                <div className="winner-header shimmer">
                    🏆🏆🏆 WIN 🏆🏆🏆
                </div>:<></>}
                <div style={{position:"relative", display:"block", height:"20px"}} onClick={()=>{executeScroll(gidx)}}>
                    
                    <div className="pbp">{(live[game.gameId])?live[game.gameId].lastPlay:"Pre-Game"}</div>
                    {(!live[game.gameId])?
                        <span className="badge bg-primary pbp-clock">{new Date(game.gameEt).getMonth()+1}/{new Date(game.gameEt).getDate()} {formatAMPM(new Date(game.gameTimeUTC))}</span>
                    :
                    <span className="badge bg-primary pbp-clock">{live[game.gameId]&&live[game.gameId].gameStatusText || game.gameStatusText}</span>
                    }
                </div>
              
              </Card.Title>
          </Card.Header>
          <Card.Body className="" style={{fontSize:"10px", padding:"0", marginBottom:"2px"}} >
          <table style={{width:"100%"}}>
            <thead>
              <tr>
                <th className="three-box">3 Pointers</th>
                <th className="score-box">Made</th>
                <th className="score-box">Att</th>
              </tr>
            </thead>
            <tbody>
            {(favorites[game.gameId].teams[game.homeTeam.teamId])?Object.values(game.homeTeam.players)
            .filter((player) => player.personId == favorite.playerId || player.personId ==  live[game.gameId]?.first.teamMade[game.homeTeam.teamId]?.personId)
            .map((player, id) => (
                <Favorite player={player} usersTailing={userPicks[player.personId]} favorite={favorite} pickFavorite={pickFavorite} game={game} live={live}  />
            )):""}
            {(favorites[game.gameId].teams[game.awayTeam.teamId])?Object.values(game.awayTeam.players)
            .filter((player) => player.personId == favorite.playerId || player.personId ==  live[game.gameId]?.first.teamMade[game.awayTeam.teamId]?.personId)
            .map((player, id) => (
                <Favorite player={player} usersTailing={userPicks[player.personId]} favorite={favorite} pickFavorite={pickFavorite} game={game} live={live}  />
            )):""}
            </tbody>
            </table>
          </Card.Body>
        </Card> 
      </Col>
      </>
    )
  },(prevProps, nextProps) => {
    return prevProps.live === nextProps.live && prevProps.game === nextProps.game; // Prevent re-render if prop is the same
  })
  export default FavoriteCard 