import React, {memo} from "react";
// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { FaMinusCircle, FaPlusCircle, FaShare, FaShareAlt, FaThumbsUp } from 'react-icons/fa';

function Addfavcolumn(props) {
    var favorites = props.favorites || {}
    var game = props.game || {}
    var player = props.player || {}
    var addFavorite = props.addFavorite || function(){}
    var userPicks = props.userPicks || 0

    if(player.rosterStatus != "Active"){
        return
    }
    return(
      <div>
        {(userPicks)?<span style={{width:"100%"}} className="badge bg-secondary">{userPicks} tailing</span>:null}
        {(!favorites[game.gameId]?.teams[player.teamId] || favorites[game.gameId]?.teams[player.teamId]?.players[player.personId]?.type == 'team')?
        <Button className="btn btn-xs" onClick={(e)=>{addFavorite({gameId: game.gameId, type: "team", teamId: player.teamId.toString(), playerId: player.personId.toString()})}}>
          {(!favorites[game.gameId]?.teams[player.teamId]?.players[player.personId])?<FaPlusCircle />:<FaMinusCircle />}
        </Button>:""}
      </div>
    )
  }


  function DkOdds(props) {
    var clock = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
    return (
      <div>
        {(props.player?.odds && clock.getHours()>=12)?"DK: "+props.player.odds:""}
      </div>
    );
  }

  function Livecolumn(props) {
    const live = props.live 
    const game = props.game
    const player = props.player
    const liveGame = live[game.gameId]
    const isFavorite = props.isFavorite || false
    const userPicks = props.userPicks || 0
    if(!liveGame){
        return;
    }
    const livePerson = liveGame.players[player.personId] || {onCourt:false, live:{}}
    const liveFirst = live[game.gameId].first
    
    return(
      <div>
        <span style={{width:"100%", textAlign:"left"}} className="badge">Live: {(livePerson?.live?.threesMade)} <span>/</span> {(livePerson?.live?.threesAttempted)}</span>
        {(livePerson.onCourt)?<span style={{width:"100%", textAlign:"left"}} className={(isFavorite)?"badge bg-dark text-white":"badge bg-dark"}>🏀 On Court</span>:""}
        
        {(liveFirst.made && liveFirst.made.personId == player?.personId)?
         <span style={{width:"100%", textAlign:"left"}} className={(isFavorite)?"badge bg-warning text-dark":"badge bg-warning text-dark"}><span className="first-three-trophy shimmer">🏆</span>&nbsp;3PM&nbsp;G</span>:
          <span>{(liveFirst.teamMade && liveFirst.teamMade[player.teamId]?.personId == player.personId)?<span style={{width:"100%", textAlign:"left"}} className={(isFavorite)?"badge bg-warning text-dark":"badge bg-warning text-dark"}><span className="first-three-trophy shimmer">🏆</span>&nbsp;3PM&nbsp;T</span>:""}</span>}
        {(liveFirst.attempt && live[game.gameId].first.attempt.personId == player.personId)?
          <span style={{width:"100%", textAlign:"left"}} className={(isFavorite)?"badge bg-dark text-white":"badge bg-dark"}>✓&nbsp;3PA&nbsp;G</span>:
          <span>{(liveFirst.teamAttempt && liveFirst.teamAttempt[player.teamId]?.personId == player.personId)?<span style={{width:"100%", textAlign:"left"}} className={(isFavorite)?"badge bg-dark text-white":"badge bg-dark"}>✓&nbsp;3PA&nbsp;T</span>:""}</span>}
        {(userPicks)?<span style={{width:"100%", textAlign:"left"}} className="badge bg-primary text-white">{userPicks} tailing</span>:null}
      </div>
    )
  }

  const PlayerList = memo((props) => {

    var favorites = props.favorites || {}
    var game = props.game || {}
    var live = props.live || {}
    var player = props.player || {}
    var picks = props.picks || {}
    var userPicks = props.userPicks || 0
    var inactive = props.inactive || false
    var proEnabled = props.proenabled || false
    var addFavorite = props.addFavorite || function(){}
    var isFavorite = picks[player.personId] || false
    var id = props.id || 1
    console.log('rerenders now')

    var removeDiacritics = (str)=> {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }

    var playerImg = (playerName) =>{
        try {
            //console.log('player img')
            var parsedName = removeDiacritics(playerName).replaceAll(" ","-")
            return require(`assets/img/player_images/${parsedName}.jpg`);
          } catch (error) {
            return require(`assets/img/player_images/player.png`);
          }
    }
    return(
      <tr key={id+player.personId} className={(isFavorite)?"highlight":""} >
        <td className="text-white" style={{width:"35%", paddingRight:10}}>
            <div className="player-container">
            <img src={playerImg(player.playerName)} className="player-img" />
            <span className="player-number">#{player.jerseyNumber}</span><span className="player-name">{player.playerName}</span>
            
            {(player.position)?(player.lineupStatus == "Confirmed")?(player.starting)?<span className="badge bg-primary">Starting {player.position}</span>:<span className="badge">Bench</span>:<span className="badge border-secondary">Lineup Pending</span>:<></>}
            
            {(false && isFavorite)?<span className="badge bg-secondary">Favorite</span>:null}
            {(player.rosterStatus != "Active")?"Inactive ":
            (player.position)?(player.lineupStatus == "Confirmed")?null:<></>:<>Bench</>}
            {(proEnabled)?<>
            {(false)?
                <p style={{fontSize:8, display:'inline-block'}}>
                    Seconds Per 3 Attempt:<span style={{float:'right'}}>{player.v2.avgsecondsPerAttempt.toFixed(2)}</span><br/>
                    (Last 5 Games):<span style={{float:'right'}}>{player.v2.l5avgsecondsPerAttempt.toFixed(2)}</span>
                    <hr style={{margin:0,background:'gray'}} />
                    Play Time:<span style={{float:'right'}}>{(player.v2.avgsecondOnCourt/60).toFixed(2)} min</span><br/>
                    (Last 5 Games):<span style={{float:'right'}}>{(player.v2.l5avgsecondOnCourt/60).toFixed(2)} min</span>
                    <hr style={{margin:0,background:'gray'}} />
                    Time Till First Attempt:<span style={{float:'right'}}>{player.v2.avgfirstMadeAtGT.toFixed(2)}</span><br/>
                    (Last 5 Games):<span style={{float:'right'}}>{player.v2.l5avgfirstMadeAtGT.toFixed(2)}</span>
                    <hr style={{margin:0,background:'gray'}} />
                    First Attempt from Bench:<span style={{float:'right'}}>{player.v2.avgsecondsTillFirstAttemptGT.toFixed(2)}</span><br/>
                    (Last 5 Games):<span style={{float:'right'}}>{player.v2.l5avgsecondsTillFirstAttemptGT.toFixed(2)}</span>
                    <hr style={{margin:0,background:'gray'}} />
                    Att Per Make: <span style={{float:'right'}}>{(1/(player.v2.threesMade/player.v2.threesAttempted)).toFixed(2)}</span><br/>
                    {
                    (((((player.v2.l5avgsecondsTillFirstAttemptGT)+((1/(player.v2.threesMade/player.v2.threesAttempted)-1)*player.v2.l5avgsecondsPerAttempt)))/60)<12)?
                    <b>
                        Latest Make Time: <span style={{float:'right'}}>{(12-(((player.v2.l5avgsecondsTillFirstAttemptGT)+((1/(player.v2.threesMade/player.v2.threesAttempted)-1)*player.v2.l5avgsecondsPerAttempt)))/60).toFixed(2)} Q1</span><br/>
                    </b>:null}
                </p>:null}
                
                <div className={(player.health_score != 5)?"injury":null}>
                    {(player.health_score != 5)?<span >Health:&nbsp;{player.health_score}/5</span>:null}
                    {(player.injury)?<span> - {player.injury}</span>:null}
                </div>
            </>
            :null}
          </div>
        </td>
        {(player.threes)?
        <>
        
        {(false)?
        <td className="text-white" style={{display:"none"}}>
          <b>
            {Object.values(player.threes.firstThree).length}/{Object.values(player.threes.firstThreeAttempt).length}<br></br>
            {(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(0):"-"}/
            {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(0):"-"}
          </b>
        </td>:null}
        <td className="text-white">
          <b>
            {Object.values(player.threes.firstTeamThree).length}/{Object.values(player.threes.firstThreeTeamAttempt).length}<br></br>
            {(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(0):"-"}/
            {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(0):"-"}
          </b>
        </td>
        <td className="text-white">
          {(player.totalThreeMade/Object.values(player.threes.attempts).length).toFixed(2)}<br />
          {(Object.values(player.threes.attempts).length)}<br />
          {player.starts}
        </td>
        {/*
        <td className="text-white">
          {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(0):"-"}/
          {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(0):"-"}
        </td>*/}
        <td className="text-white">{player.totalThreeMade}/{player.totalThreeAttempts}<br/>{((player.totalThreeMade/player.totalThreeAttempts*100) || 0).toFixed(2)}%</td>
        <td>
          <DkOdds player={player}/>
          <span style={{width:"100%", textAlign:"left", fontSize:"8px"}} className={(isFavorite)?"badge bg-dark text-white":"badge bg-white text-dark"}>
            AI Rank:&nbsp;<b>{player.rank}</b>
          </span>
         
        </td>
        <td>
          
          {(live[game.gameId] && live[game.gameId].lastPlay)?(
            <Livecolumn userPicks={userPicks} isFavorite={isFavorite} player={player} live={live} game={game} />
            ):(
            <Addfavcolumn userPicks={userPicks} isFavorite={isFavorite} favorites={favorites} game={game} player={player} addFavorite={addFavorite}/>
            )
          }
          
        </td>
        </>:<td colSpan="1000">No Data</td>}
    </tr>
    )
  },(prevProps, nextProps) => {
    return prevProps.game === nextProps.game && prevProps.live === nextProps.live && prevProps.player === nextProps.player; // Prevent re-render if prop is the same
  })

  export default PlayerList;